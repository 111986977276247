import {
  BRANDS,
  type Brand,
  CAMPAIGNS,
  type Campaign,
  PRODUCTS,
  type Product,
  brandSchema,
  campaignSchema,
  productSchema,
} from "@influ-app/modeldb";
import { child, get, getDatabase, ref } from "firebase/database";
import { toPairs, values } from "lodash";
import { z } from "zod";
import { app } from "../utils/firebase";

const LOG_PREFIX = "getMarketService:";

export type IProfileRequirements = Record<string, unknown>;

export interface IBrand {
  id: string;
  name: string;
}

export interface IProduct {
  id: string;
  name: string;
  brand: IBrand;
}

export interface ICampaign {
  id: string;
  title: string;
  brief: string;
  products: IProduct[];
  profileRequirements: IProfileRequirements;
}

export interface IMarket {
  name: string;
  campaigns: ICampaign[];
}

export const getMaket = async (): Promise<IMarket> => {
  const db = getDatabase(app);

  const dbRef = ref(db);

  const market: IMarket = {
    name: "Marketplace",
    campaigns: [],
  };

  const campaigns = await get(child(dbRef, `${CAMPAIGNS}`)).then(
    async (snapshot) => {
      if (!snapshot.exists()) {
        return [];
      }
      return await z.array(campaignSchema).parseAsync(values(snapshot.val()));
    },
  );

  const getProducts = async (campaign: Campaign): Promise<Product[]> => {
    const productRef = toPairs(campaign.products);
    const products: Product[] = [];

    for (const [id] of productRef) {
      const snapshot = await get(child(dbRef, `${PRODUCTS}/${id}`));
      if (!snapshot.exists()) {
        continue;
      }
      products.push(await productSchema.parse(snapshot.val()));
    }
    console.debug(
      LOG_PREFIX,
      `Found products for campaign: ${campaign?.id}`,
      products,
    );
    return products;
  };

  const getBrand = async (product: Product): Promise<Brand> => {
    const [brandId] = toPairs(product?.brand)[0];

    return await get(child(dbRef, `${BRANDS}/${brandId}`)).then((snapshot) => {
      if (!snapshot.exists()) {
        return null as unknown as Brand;
      }
      return brandSchema.parse(snapshot.val());
    });
  };

  for (const campaign of campaigns) {
    const products = await getProducts(campaign);

    market.campaigns.push({
      id: campaign.id,
      title: campaign.title,
      brief: campaign.brief,
      products: await Promise.all(
        products?.map(
          async (p) =>
            ({
              id: p.id,
              name: p.name,
              brand: await getBrand(p).then((p) => ({
                id: p?.id,
                name: p?.name,
              })),
            }) as IProduct,
        ),
      ),
      profileRequirements: {},
    });
  }

  console.debug(LOG_PREFIX, "Market:", market);

  return market;
};
