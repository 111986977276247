import { type ItemType, type MenuItemType } from "antd/es/menu/hooks/useItems";
import { User as FirebaseUser, type UserInfo } from "firebase/auth";
import { capitalize, split } from "lodash";
import { create } from "zustand";
import { loginFirebase, logoutFirebase } from "../services/authService";
import { getUser } from "../services/userService";
import {
  IInfluencer,
  getInfluencerService,
} from "../services/getInfluencerService";
import {
  IBrandUser,
  getBrandUserService,
} from "../services/getBrandUserService";
import {
  IAdminUser,
  getAdminUserService,
} from "../services/getAdminUserService";

const LOG_PREFIX = "appSessionStore:";

interface CurrentPage {
  pageTitle: string;
  pageSubTitle: string;
}

export interface Influencer {
  fullname: string;
  category: string;
}

export interface Brand {
  name: string;
  active: boolean;
}

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;

  account: Influencer | Brand | undefined;
}

export const isInfluencer = function (
  it?: IInfluencer | IBrandUser | IAdminUser,
): it is IInfluencer {
  return (it as IInfluencer)?.type === "INFLUENCER";
};
export const isBrand = function (
  it?: IInfluencer | IBrandUser | IAdminUser,
): it is IBrandUser {
  return (it as IBrandUser)?.type === "BRAND";
};
export const isAdminUser = function (
  it?: IInfluencer | IBrandUser | IAdminUser,
): it is IAdminUser {
  return (it as IAdminUser)?.type === "ADMIN";
};

type MenuItem = ItemType<MenuItemType>;

interface StoreState {
  firebaseUser?: UserInfo;
  user?: IInfluencer | IBrandUser | IAdminUser;
  token?: string;
  menuItems?: MenuItem[];

  homePagePath?: string;
  accountPagePath?: string;

  currentPage?: CurrentPage;
}

interface StoreActions {
  login: (username: string, password: string) => Promise<void>;
  updateUser: (user: FirebaseUser) => Promise<void>;
  logout: () => Promise<void>;
  setCurrentPage: (key: string) => void;
}

const defaults: StoreState = {
  firebaseUser: undefined,
  user: undefined,

  token: undefined,
  menuItems: [],

  homePagePath: undefined,
  accountPagePath: undefined,

  currentPage: { pageTitle: "", pageSubTitle: "" },
};

export const useAppSessionStore = create<StoreState & StoreActions>()(
  (set, get) => ({
    ...defaults,
    // actions
    setCurrentPage: (key: string) => {
      set({
        currentPage: {
          pageSubTitle: "--",
          pageTitle: capitalize(split(key, "/").pop() || "unknown"),
        },
      });
    },
    updateUser: async (firebaseUser: UserInfo) => {
      if (!firebaseUser) {
        console.warn(LOG_PREFIX, "user is undefined");
        set({ ...get(), ...defaults });
        return;
      }

      console.log(LOG_PREFIX, "logged firebase user", firebaseUser);

      const userId = firebaseUser?.uid;

      if (!userId) {
        console.warn(LOG_PREFIX, "userId is undefined");
        set({ user: undefined });
        return;
      }

      const userItem = await getUser(firebaseUser.uid);

      console.log(LOG_PREFIX, "User data:", {
        user: firebaseUser,
        userItem: userItem,
      });

      if (!userItem) {
        console.warn(LOG_PREFIX, "userDto is undefined");
        return;
      }

      if (userItem?.role === "influencer") {
        const influencerUser = await getInfluencerService(userId);
        const influencerId = influencerUser?.influecer?.id;
        set({
          firebaseUser: firebaseUser,
          user: influencerUser,
          menuItems: [
            {
              label: "Dashboard",
              key: `/influencers/${influencerId}/dashboard`,
            },
            { label: "Contents", key: `/influencers/${influencerId}/contents` },
            {
              label: "Marketplace",
              key: `/influencers/${influencerId}/marketplace`,
            },
          ],
          homePagePath: `/influencers/${influencerId}/dashboard`,
          accountPagePath: `/influencers/${influencerId}/account`,
          currentPage: { pageTitle: "Dashboard", pageSubTitle: "--" },
        });
        return;
      }

      if (userItem?.role === "brand") {
        const userBrand = await getBrandUserService(userId);
        const brandId = userBrand?.brand?.id;

        set({
          firebaseUser: firebaseUser,
          user: userBrand,
          menuItems: [
            { label: "Dashboard", key: `/brands/${brandId}/dashboard` },
            { label: "Products", key: `/brands/${brandId}/products` },
            { label: "Campaigns", key: `/brands/${brandId}/campaigns` },
            { label: "Contents", key: `/brands/${brandId}/contents` },
          ],
          homePagePath: `/brands/${brandId}/dashboard`,
          accountPagePath: `/brands/${brandId}/account`,
          currentPage: { pageTitle: "brand/Dashboard", pageSubTitle: "--" },
        });
        return;
      }

      if (userItem?.role === "admin") {
        const adminUser = await getAdminUserService(userId);
        set({
          firebaseUser: firebaseUser,
          user: adminUser,
          menuItems: [
            { label: "Dashboard", key: `/admin/${userId}/dashboard` },
            { label: "Invite", key: `/admin/${userId}/invite` },
            { label: "Brands", key: `/admin/${userId}/brands` },
          ],
          homePagePath: `/admin/${userId}/dashboard`,
          accountPagePath: `/brands/${userId}/account`,
          currentPage: { pageTitle: "admin/Dashboard", pageSubTitle: "--" },
        });
      }
    },

    login: async (username: string, password: string) => {
      await loginFirebase(username, password);
    },

    logout: async () => {
      await logoutFirebase();
      set({ ...get(), ...defaults });
    },
  }),
);
