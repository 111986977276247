import { createBrowserRouter } from "react-router-dom";
import AccountPage from "./pages/account/AccountPage";
import { CampaignsPage } from "./pages/campaigns/CampaignsPages";
import { DashboardPage } from "./pages/dashboard/DashboardPages";
import ErrorPage from "./pages/error/ErrorPage";
import InvitePage from "./pages/invite/InvitePage";
import { ContentsPage } from "./pages/list-contents/ListContentsPages";
import LoginPage from "./pages/login/LoginPage";
import ManageBrandsPage from "./pages/manage-brands/ManageBrandsPage";
import { MarketPlacePage } from "./pages/marketplace/MarketPlacepPage";
import { ProductsPage } from "./pages/products/ProductsPages";
import { ReviewContentsPage } from "./pages/review-contents/ReviewContentsPage";
import { Root } from "./routes/root";
import { MarketPlaceCampaignPage } from "./pages/marketplace/MarketPlaceCampaignPage";

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/influencers/:influencerId",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "contents",
        element: <ContentsPage />,
      },
      {
        path: "marketplace",
        element: <MarketPlacePage />,
      },
      {
        path: "campaigns/:campaignId",
        element: <MarketPlaceCampaignPage />,
      },
    ],
  },
  {
    path: "/brands/:brandId",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "products",
        element: <ProductsPage />,
      },
      {
        path: "campaigns",
        element: <CampaignsPage />,
      },
      {
        path: "contents",
        children: [
          {
            element: <ContentsPage />,
          },
          {
            path: ":contentId/review",
            element: <ReviewContentsPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/admin/:userId",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "dashboard",
        element: <DashboardPage />,
      },
      {
        path: "invite",
        element: <InvitePage />,
      },
      {
        path: "brands",
        element: <ManageBrandsPage />,
      },
    ],
  },
]);
