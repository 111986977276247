import { signInWithEmailAndPassword } from "firebase/auth";
import { unstable_batchedUpdates } from "react-dom";
import { useAppSessionStore } from "../stores/appSessionStore";
import { auth } from "../utils/firebase";

const LOG_PREFIX = "authService:";

auth.onAuthStateChanged((user) => {
  unstable_batchedUpdates(() => {
    if (!user) {
      console.warn(LOG_PREFIX, "no user");
      return;
    }
    useAppSessionStore.getState().updateUser(user);
  });
});

export const loginFirebase = async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password,
  );
  return userCredential.user;
};

export const logoutFirebase = async () => {
  await auth.signOut();
};
