import { getDatabase, ref, child, get } from "firebase/database";
import { USERS, type User, userSchema } from "@influ-app/modeldb";
import { app } from "../utils/firebase";

export const getUser = async (firebaseUserId: string): Promise<User | null> => {
  const db = getDatabase(app);

  const dbRef = ref(db);

  return await get(child(dbRef, `${USERS}/${firebaseUserId}`)).then(
    async (snapshot) => {
      if (!snapshot.exists()) return null;
      return await userSchema.parseAsync(snapshot.val());
    },
  );
};
