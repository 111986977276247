import { USERS, User } from "@influ-app/modeldb";
import { child, get, getDatabase, ref } from "firebase/database";
import { app } from "../utils/firebase";

const LOG_PREFIX = "getAdminUserService:";

export type IAdminUser = Omit<User, "account"> & { type: "ADMIN" };

export const getAdminUserService = async (
  userId: string,
): Promise<IAdminUser> => {
  const db = getDatabase(app);
  const dbRef = ref(db);

  const user = await get(child(dbRef, `${USERS}/${userId}`)).then(
    (snampshot) => {
      if (!snampshot.exists()) {
        return null;
      }
      return snampshot.val() as User;
    },
  );

  if (!user) {
    console.error(LOG_PREFIX, "User not found", `${USERS}/${userId}`);
    throw new Error("User not found");
  }

  const ret: IAdminUser = {
    active: user.active,
    referralSource: user.referralSource,
    role: user.role,
    type: "ADMIN",
    userId: user.userId,
  };

  console.debug(LOG_PREFIX, "AdminUser data:", ret);

  return ret;
};
