import { Alert, Button, Checkbox, Form, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSessionStore } from "../../stores/appSessionStore";
import Styles from "./LoginPage.module.scss";

const { Title } = Typography;

const LOG_PREFIX = "LoginPage:";

type FormFieldTypes = {
  username?: string;
  password?: string;
  remember?: string;
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { login, user, firebaseUser, homePagePath } = useAppSessionStore();
  const [error, setError] = useState({ hasError: false, message: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(LOG_PREFIX, "user", user);
    if (!firebaseUser?.uid) {
      return;
    }
    navigate(homePagePath as string);
  }, [user, navigate, homePagePath]);

  const handleOnFinish = async (values: FormFieldTypes) => {
    setLoading(true);
    setError({ message: "", hasError: false });

    await login(values?.username as string, values?.password as string).catch(
      (err) => {
        // TODO: implement Firebase error handling
        if (err.code === "auth/invalid-credential") {
          setError({
            message: "Error logging in. Please try again.",
            hasError: true,
          });
        }

        setLoading(false);
      },
    );
  };

  const handleOnInput = () => {
    setError({ message: "", hasError: false });
  };

  const alertMessage: JSX.Element[] = [];

  if (error.hasError) {
    alertMessage.push(
      <Alert
        key={"alert-error"}
        message={error.message}
        type="error"
        showIcon={true}
      />,
    );
  } else {
    alertMessage.push(
      <Alert
        style={{ visibility: "hidden" }}
        key={"alert-error"}
        message={"no_error"}
        type="error"
        showIcon={true}
      />,
    );
  }

  return (
    <div className={Styles.container}>
      <div>
        <Title level={1}>InfluApp - Login</Title>
      </div>
      <div>{alertMessage}</div>
      <Form
        initialValues={{ remember: true }}
        onFinish={handleOnFinish}
        onInput={handleOnInput}
        autoComplete="off"
      >
        <Form.Item<FormFieldTypes>
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input readOnly={loading} />
        </Form.Item>

        <Form.Item<FormFieldTypes>
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password readOnly={loading} />
        </Form.Item>

        <Form.Item<FormFieldTypes> name="remember" valuePropName="checked">
          <Checkbox disabled={loading}>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            block={true}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
