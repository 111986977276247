import { Button, Card, Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ICampaign, getMaket } from "../../services/getMarketService";
import Styles from "./MarketPlacepPage.module.scss";

const CampaignCard: React.FC<ICampaign> = (props) => {
  const navigate = useNavigate();

  const handleShowMore = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`../campaigns/${props.id}`);
  };

  return (
    <Card
      title={props?.title}
      bordered={true}
      extra={
        <Button type="link" onClick={handleShowMore}>
          more
        </Button>
      }
    >
      {props?.brief}
    </Card>
  );
};

export const MarketPlacePage: React.FC = () => {
  const { isLoading, data } = useQuery("market", () => getMaket());

  if (isLoading) {
    return (
      <div className={Styles.skeleton}>
        <Skeleton.Input active={true} size="large" />
        <Skeleton.Input
          active={true}
          size="large"
          className={Styles.skeleton_block}
        />
        <Skeleton.Input
          active={true}
          size="large"
          className={Styles.skeleton_block}
        />
        <Skeleton.Input
          active={true}
          size="large"
          className={Styles.skeleton_block}
        />
        <Skeleton.Input
          active={true}
          size="large"
          className={Styles.skeleton_block}
        />
        <Skeleton.Input
          active={true}
          size="large"
          className={Styles.skeleton_block}
        />
        <Skeleton.Input
          active={true}
          size="large"
          className={Styles.skeleton_block}
        />
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <h1>{data?.name}</h1>
      <div className={Styles.grid_of_campaigns}>
        {data?.campaigns?.map((c) => <CampaignCard key={c.id} {...c} />)}
      </div>
    </div>
  );
};
