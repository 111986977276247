import { UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSessionStore } from "../../stores/appSessionStore";

const LOG_PREFIX = "UserProfile:";

enum Actions {
  ACCOUNT = "account",
  SETTINGS = "settings",
  LOGOUT = "logout",
}

const items: MenuProps["items"] = [
  {
    key: Actions.ACCOUNT,
    label: "Account",
  },
  {
    key: Actions.SETTINGS,
    label: "Settings",
    disabled: true,
  },
  {
    type: "divider",
  },
  {
    key: Actions.LOGOUT,
    label: "Logout",
  },
];

const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const { firebaseUser, logout, accountPagePath } = useAppSessionStore();

  const handleKey = async (key: Actions) => {
    switch (key) {
      case Actions.ACCOUNT:
        navigate(accountPagePath as string);
        break;
      case Actions.SETTINGS:
        break;
      case Actions.LOGOUT:
        await logout();
        console.info(LOG_PREFIX, "user logout");
        navigate("/login", {
          replace: true,
        });
        break;
    }
  };

  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        items,
        onClick: ({ key }) => handleKey(key as Actions),
      }}
    >
      <Button onClick={(e) => e.preventDefault()} type="link">
        <Space>
          <span>{firebaseUser?.email}</span>
          <UserOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default UserProfile;
