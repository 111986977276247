"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAMPAIGNS = exports.CONTENTS = exports.INFLUENCERS = exports.PRODUCTS = exports.RESOURCE = exports.SOCIAL_MEDIA_ACCOUNTS = exports.USERS = exports.BRANDS = void 0;
exports.BRANDS = "brands";
exports.USERS = "users";
exports.SOCIAL_MEDIA_ACCOUNTS = "socialMediaAccounts";
exports.RESOURCE = "resources";
exports.PRODUCTS = "products";
exports.INFLUENCERS = "influencers";
exports.CONTENTS = "contents";
exports.CAMPAIGNS = "campaigns";
