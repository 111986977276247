"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.socialMediaAccountSchema = void 0;
const uuid_1 = require("uuid");
const zod_1 = require("zod");
const CollectionNames_1 = require("./CollectionNames");
const utils_1 = require("./utils");
exports.socialMediaAccountSchema = zod_1.z.object({
    id: zod_1.z.string().default(uuid_1.v4),
    platform: zod_1.z.string(),
    username: zod_1.z.string(),
    accountId: zod_1.z.string().optional(),
    profileUrl: zod_1.z.string().optional(),
    followers: zod_1.z.number(),
    createdDate: zod_1.z.date().default(() => new Date()),
    influencer: zod_1.z.record(zod_1.z.enum([CollectionNames_1.INFLUENCERS]))
        .refine(utils_1.onlyOneItem, "Only one influencer is allowed"),
});
