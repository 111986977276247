import React from "react";

const ManageBrandsPage: React.FC = () => {
  const brands = ["Brand 1", "Brand 2", "Brand 3"];

  return (
    <div>
      <h1>Manage Brands</h1>
      <ul>
        {brands.map((brand, index) => (
          <li key={index}>{brand}</li>
        ))}
      </ul>
    </div>
  );
};

export default ManageBrandsPage;
