import { Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { isInfluencer, useAppSessionStore } from "../../stores/appSessionStore";
import { nameofFactory } from "../../utils/type-checkers";

const { Text } = Typography;

type InfluencerUser = {
  email: string;
  fullname: string;
  category: string;
};

type ComponentProps = {
  onSave: (data: InfluencerUser) => void;
};

type FormType = {
  fullname: string;
  email: string;
  category: string;
};

const formNames = nameofFactory<FormType>();

const InfluencerProfileForm: React.FC<ComponentProps> = () => {
  const { user, firebaseUser } = useAppSessionStore();
  const [formData] = useForm<FormType>();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user?.role !== "influencer" || !isInfluencer(user)) {
      throw new Error("User is not an influencer");
    }

    formData.setFieldValue(formNames("email"), firebaseUser?.email ?? "");
    formData.setFieldValue(
      formNames("fullname"),
      user?.influecer?.fullname ?? "",
    );
    formData.setFieldValue(
      formNames("category"),
      user?.influecer?.category ?? "",
    );
  }, [formData, user, user, firebaseUser?.email, user?.role]);

  const onFinish = (values: FormType) => {
    console.log("Success:", values);
  };

  const onFinishFailed = () => {};

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={formData}
      layout="vertical"
    >
      <Form.Item>
        <Text type="warning">
          Work in Progress &mdash; This form is not yet functional
        </Text>
      </Form.Item>
      <Form.Item label="Email" name={formNames("email")} required={true}>
        <Input readOnly={true} size="large" />
      </Form.Item>

      <Form.Item label="Full Name" name={formNames("fullname")} required={true}>
        <Input readOnly={true} size="large" />
      </Form.Item>

      <Form.Item label="Category" name={formNames("category")} required={true}>
        <Input readOnly={true} size="large" />
      </Form.Item>
    </Form>
  );
};

export default InfluencerProfileForm;
