import { Form, Input, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import { isBrand, useAppSessionStore } from "../../stores/appSessionStore";
import { nameofFactory } from "../../utils/type-checkers";

const { Text } = Typography;

type BrandUser = {
  name: string;
  actice: boolean;
};

type ComponentProps = {
  onSave: (data: BrandUser) => void;
};

type FormType = {
  fullname: string;
  email: string;
  brandName: string;
  brandActive: string;
};

const formNames = nameofFactory<FormType>();

const BrandProfileForm: React.FC<ComponentProps> = () => {
  const { user, firebaseUser } = useAppSessionStore();
  const [formData] = useForm<FormType>();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user?.role !== "brand" || !isBrand(user)) {
      throw new Error("User is not a Brand");
    }

    formData.setFieldValue(formNames("email"), firebaseUser?.email ?? "");
    formData.setFieldValue(formNames("brandName"), user?.brand?.name ?? "");
    formData.setFieldValue(formNames("brandActive"), user?.active ?? "");
  }, [formData, user, user, firebaseUser?.email, user?.role]);

  const onFinish = (values: FormType) => {
    console.log("Success:", values);
  };

  const onFinishFailed = () => {};

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={formData}
      layout="vertical"
    >
      <Form.Item>
        <Text type="warning">
          Work in Progress &mdash; This form is not yet functional
        </Text>
      </Form.Item>
      <Form.Item label="Email" name={formNames("email")} required={true}>
        <Input readOnly={true} size="large" />
      </Form.Item>

      <Form.Item label="Full Name" name={formNames("fullname")} required={true}>
        <Input readOnly={true} size="large" />
      </Form.Item>

      <Form.Item
        label="Brand Name"
        name={formNames("brandName")}
        required={true}
      >
        <Input readOnly={true} size="large" />
      </Form.Item>

      <Form.Item
        label="Brand Active"
        name={formNames("brandActive")}
        required={true}
      >
        <Input readOnly={true} size="large" />
      </Form.Item>
    </Form>
  );
};

export default BrandProfileForm;
