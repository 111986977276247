import { Menu } from "antd";
import React from "react";
import { useAppSessionStore } from "../../stores/appSessionStore";
import { useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import UserProfile from "../user-profile/UserProfile";
import Styles from "./Topbar.module.scss";

export interface MenuInfo {
  key: string;
  keyPath: string[];
  /** @deprecated This will not support in future. You should avoid to use this */
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

const LOG_PREFIX = "Topbar:";

type Props = Record<string, never>;

const TopBar: React.FC<Props> = () => {
  const { menuItems, setCurrentPage } = useAppSessionStore();
  const navigate = useNavigate();

  const handleClickMenu = (e: MenuInfo) => {
    console.log(LOG_PREFIX, "navigate", e);
    setCurrentPage(e.key);
    navigate(e.key);
  };

  return (
    <Header
      className={Styles.container}
      //style={{ position: 'fixed', zIndex: 1, width: '100%' }}
    >
      <div className={Styles.elements}>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={menuItems}
          style={{ flex: 1, minWidth: 0 }}
          onClick={handleClickMenu}
        />
        <UserProfile />
      </div>
    </Header>
  );
};

export default TopBar;
