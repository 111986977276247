"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentSchema = void 0;
const zod_1 = require("zod");
const utils_1 = require("./utils");
const uuid_1 = require("uuid");
exports.contentSchema = zod_1.z.object({
    id: zod_1.z.string().default(uuid_1.v4),
    title: zod_1.z.string(),
    type: zod_1.z.enum(["review", "unboxing", "video", "blogpost", "socialpost", "image", "podcast", "other"]),
    resources: zod_1.z.record(zod_1.z.string())
        .refine(utils_1.oneOrManyItems, "One or many resources are allowed")
        .optional(),
    campaign: zod_1.z.record(zod_1.z.string())
        .refine(utils_1.onlyOneItem, "Only one campaign is allowed"),
    influencer: zod_1.z.record(zod_1.z.string())
        .refine(utils_1.onlyOneItem, "Only one influencer is allowed")
});
