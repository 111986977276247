import { Button, Form, Input, Select } from "antd";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { postInfluencerNewContent } from "../../services/postInfluencerNewContent";
import { useAppSessionStore } from "../../stores/appSessionStore";
import { nameofFactory } from "../../utils/type-checkers";

const LOG_PREFIX = "CreateContentForm:";

type FormType = {
  title: string;
  type:
    | "review"
    | "unboxing"
    | "video"
    | "blogpost"
    | "socialpost"
    | "image"
    | "podcast"
    | "other";
  url: string;
};

const formNames = nameofFactory<FormType>();

const CreateContentForm: React.FC = () => {
  const queryClient = useQueryClient();
  const params = useParams<{ campaignId: string; influencerId: string }>();
  const { firebaseUser } = useAppSessionStore();
  const [form] = Form.useForm<FormType>();
  const initialValues: FormType = {
    url: "",
    type: "video",
    title: "",
  };

  const { mutate } = useMutation(postInfluencerNewContent, {
    onSuccess(data, variables, context) {
      console.debug(LOG_PREFIX, "MutationSuccess", data, variables, context);
    },
    onError(error, variables, context) {
      console.error(LOG_PREFIX, "MutationError", error, variables, context);
    },
    onSettled(data, error, variables, context) {
      console.debug(
        LOG_PREFIX,
        "MutationSettled",
        data,
        error,
        variables,
        context,
      );
      queryClient.invalidateQueries("create");
    },
  });
  const onFinish = (values: FormType) => {
    mutate({
      influencerId: params?.influencerId as string,
      title: values?.title,
      type: values?.type,
      url: values?.url,
      campaignId: "campaignId",
      userId: firebaseUser?.uid as string,
    });
  };

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <Form.Item
        label="Title"
        name={formNames("title")}
        rules={[{ required: true, message: "Please enter a title" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Type" name={formNames("type")}>
        <Select
          options={[
            { value: "video", label: "Video" },
            { value: "post", label: "Post" },
            { value: "story", label: "Story" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Url"
        name={formNames("url")}
        rules={[{ required: true, message: "Please enter a url" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateContentForm;
