"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.campaignSchema = void 0;
const zod_1 = require("zod");
const CollectionNames_1 = require("./CollectionNames");
const uuid_1 = require("uuid");
const utils_1 = require("./utils");
exports.campaignSchema = zod_1.z.object({
    id: zod_1.z.string().default(uuid_1.v4),
    title: zod_1.z.string().min(1),
    brief: zod_1.z.string().min(1),
    startDate: zod_1.z.string().datetime(),
    endDate: zod_1.z.string().datetime(),
    profileRequirements: zod_1.z.record(zod_1.z.unknown()),
    products: zod_1.z.record(zod_1.z.enum([CollectionNames_1.PRODUCTS])),
    contents: zod_1.z.record(zod_1.z.enum([CollectionNames_1.CONTENTS])).optional(),
    brand: zod_1.z.record(zod_1.z.enum([CollectionNames_1.BRANDS]))
        .refine(utils_1.onlyOneItem, "Only one brand is allowed"),
    active: zod_1.z.boolean(),
});
