import React, { useMemo } from "react";
import {
  isAdminUser,
  isBrand,
  isInfluencer,
  useAppSessionStore,
} from "../../stores/appSessionStore";
import Styles from "./AccountPage.module.scss";
import AdminUserProfileForm from "./AdminUserForm";
import BrandProfileForm from "./BrandForm";
import InfluencerProfileForm from "./InfluencerForm";

type ComponentProps = Record<string, never>;

enum Role {
  influencer = "influencer",
  brand = "brand",
  admin = "admin",
}

const AccountPage: React.FC<ComponentProps> = () => {
  const { user } = useAppSessionStore();
  const role = useMemo((): Role => {
    if (isInfluencer(user)) {
      return Role.influencer;
    } else if (isBrand(user)) {
      return Role.brand;
    } else if (isAdminUser(user)) {
      return Role.admin;
    } else {
      return null as never;
    }
  }, [user]);

  return (
    <div className={Styles.container}>
      {(() => {
        switch (role) {
          case Role.influencer:
            return <InfluencerProfileForm onSave={() => {}} />;
          case Role.brand:
            return <BrandProfileForm onSave={() => {}} />;
          case Role.admin:
            return <AdminUserProfileForm onSave={() => {}} />;
          default:
            return <div>Unkown role</div>;
        }
      })()}
    </div>
  );
};

export default AccountPage;
