import { BRANDS, Brand, USERS, User } from "@influ-app/modeldb";
import { child, get, getDatabase, ref } from "firebase/database";
import { toPairs } from "lodash";
import { app } from "../utils/firebase";

const LOG_PREFIX = "getBrandUserService:";

export type IBrandUser = Omit<User, "account"> & {
  brand: Omit<Brand, "users" | "products" | "campaigns">;
} & { type: "BRAND" };

export const getBrandUserService = async (
  userId: string,
): Promise<IBrandUser> => {
  const db = getDatabase(app);
  const dbRef = ref(db);

  const user = await get(child(dbRef, `${USERS}/${userId}`)).then(
    (snampshot) => {
      if (!snampshot.exists()) {
        return null;
      }
      return snampshot.val() as User;
    },
  );

  if (!user) {
    console.error(LOG_PREFIX, "User not found", `${USERS}/${userId}`);
    throw new Error("User not found");
  }

  const [brandId] = toPairs(user?.account)[0];

  const brand = await get(child(dbRef, `${BRANDS}/${brandId}`)).then(
    (snampshot) => {
      if (!snampshot.exists()) {
        return null;
      }
      return snampshot.val() as Brand;
    },
  );

  if (!brand) {
    console.error(LOG_PREFIX, "Brnad ref not found", `${USERS}/${userId}`);
    throw new Error("Brand not found");
  }

  const ret: IBrandUser = {
    active: brand.active,
    brand: {
      id: brand.id,
      name: brand.name,
      active: brand.active,
    },
    referralSource: user.referralSource,
    role: user.role,
    type: "BRAND",
    userId: user.userId,
  };

  console.debug(LOG_PREFIX, "BrandUser data:", ret);

  return ret;
};
