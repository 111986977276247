import React, { useState } from "react";

interface Invitation {
  email: string;
}

const InvitePage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleInvite = () => {
    if (email.trim() !== "") {
      const newInvitation: Invitation = { email };
      setInvitations([...invitations, newInvitation]);
      setEmail("");
    }
  };

  return (
    <div>
      <h1>Invite Influencers</h1>
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="Enter email"
      />
      <button onClick={handleInvite}>Invite</button>
      <h2>Invitations:</h2>
      <ul>
        {invitations.map((invitation, index) => (
          <li key={index}>{invitation.email}</li>
        ))}
      </ul>
    </div>
  );
};

export default InvitePage;
