import {
  INFLUENCERS,
  SOCIAL_MEDIA_ACCOUNTS,
  SocialMediaAccount,
  USERS,
  Influencer,
  User,
} from "@influ-app/modeldb";
import { child, get, getDatabase, ref } from "firebase/database";
import { toPairs } from "lodash";
import { app } from "../utils/firebase";

const LOG_PREFIX = "getInfluencerService:";

export type IInfluencer = Omit<User, "account"> & {
  influecer: Omit<Influencer, "accounts" | "user"> & {
    accounts: SocialMediaAccount[];
  };
} & { type: "INFLUENCER" };

export const getInfluencerService = async (
  userId: string,
): Promise<IInfluencer> => {
  const db = getDatabase(app);
  const dbRef = ref(db);

  const user = await get(child(dbRef, `${USERS}/${userId}`)).then(
    (snampshot) => {
      if (!snampshot.exists()) {
        return null;
      }
      return snampshot.val() as User;
    },
  );

  if (!user) {
    console.error(LOG_PREFIX, "User not found", `${USERS}/${userId}`);
    throw new Error("User not found");
  }

  const [influencerId] = toPairs(user?.account)[0];

  const influencer = await get(
    child(dbRef, `${INFLUENCERS}/${influencerId}`),
  ).then((snampshot) => {
    if (!snampshot.exists()) {
      return null;
    }
    return snampshot.val() as Influencer;
  });

  if (!influencer) {
    console.error(LOG_PREFIX, "Influencer ref not found", `${USERS}/${userId}`);
    throw new Error("Influencer not found");
  }

  const snsAccountsIds = toPairs(influencer?.accounts);

  const snsAccounts: SocialMediaAccount[] = [];

  console.debug(LOG_PREFIX, "preapring to read", snsAccounts);

  for (const [snsId] of snsAccountsIds) {
    const path = `${SOCIAL_MEDIA_ACCOUNTS}/${snsId}`;
    const account = await get(child(dbRef, path)).then((snampshot) => {
      if (!snampshot.exists()) {
        return null;
      }
      return snampshot.val() as SocialMediaAccount;
    });
    console.debug(LOG_PREFIX, "read", path, account);

    if (!account) {
      continue;
    }
    snsAccounts.push(account as SocialMediaAccount);
  }

  const ret: IInfluencer = {
    influecer: {
      id: influencer?.id,
      fullname: influencer?.fullname,
      category: influencer?.category,
      accounts: snsAccounts,
    },
    referralSource: user?.referralSource,
    role: user?.role,
    active: user?.active,
    userId: userId,
    type: "INFLUENCER",
  };

  console.debug(LOG_PREFIX, "InfluencerUser data:", ret);

  return ret;
};
