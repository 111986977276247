import { LeftOutlined } from "@ant-design/icons";
import { Button, List, Skeleton, Typography } from "antd";
import { chain, toString } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getMaketCampaign } from "../../services/getMarketCampaignService";
import Styles from "./MarketPlaceCampaignPage.module.scss";
import CreateContentForm from "./CreateContentForm";

const { Text } = Typography;
const LOG_PREFIX = "MarketPlaceCampaignPage:";

type PageProps = Record<string, never>;
export const MarketPlaceCampaignPage: React.FC<PageProps> = () => {
  const navigate = useNavigate();
  const params = useParams<{ campaignId: string; influencerId: string }>();
  const { isLoading, error, data } = useQuery("campaign", () =>
    getMaketCampaign(params),
  );

  if (error) {
    return <div>Error: {toString(error)}</div>;
  }

  const handleBackClick = () => {
    console.debug(LOG_PREFIX, "navigate back");
    navigate("../marketplace");
  };

  return (
    <div className={Styles.container}>
      <Skeleton loading={isLoading} active={true}>
        <div className={Styles.campaign_header_section}>
          <div>
            <Button type="link" onClick={handleBackClick}>
              <LeftOutlined />
            </Button>
          </div>
          <div>
            <h1>{data?.title}</h1>
          </div>
        </div>
        <div className={Styles.brief_section}>
          <Text>{data?.brief}</Text>
        </div>
        <div className={Styles.products_section}>
          <List
            dataSource={data?.products ?? []}
            renderItem={(item) => (
              <List.Item>
                {item?.name}/{item?.brand?.name}
              </List.Item>
            )}
          />
        </div>
        <div className={Styles.requirements_section}>
          {chain(data?.profileRequirements)
            .toPairs()
            .map(([key, value]) => (
              <Text key={`text-${key}`}>
                {key}:{toString(value)}
              </Text>
            ))
            .value()}
        </div>
        <div className={Styles.create_content}>
          <h3>Create Content</h3>
          <CreateContentForm />
        </div>
      </Skeleton>
    </div>
  );
};
