"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userSchema = void 0;
const zod_1 = require("zod");
const CollectionNames_1 = require("./CollectionNames");
const utils_1 = require("./utils");
exports.userSchema = zod_1.z.object({
    userId: zod_1.z.string().min(3),
    active: zod_1.z.boolean(),
    referralSource: zod_1.z.string(),
    account: zod_1.z.record(zod_1.z.enum([CollectionNames_1.INFLUENCERS, CollectionNames_1.BRANDS, CollectionNames_1.USERS]))
        .refine(utils_1.onlyOneItem, "Only one account is allowed"),
    role: zod_1.z.enum(["admin", "influencer", "brand"]),
});
