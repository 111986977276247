import {
  BRANDS,
  CAMPAIGNS,
  PRODUCTS,
  brandSchema,
  campaignSchema,
  productSchema,
  type Brand,
  type Campaign,
  type Product,
} from "@influ-app/modeldb";
import { child, get, getDatabase, ref } from "firebase/database";
import { keys } from "lodash";
import { app } from "../utils/firebase";

const LOG_PREFIX = "getMarketCampaignService:";

export type IProfileRequirements = Record<string, unknown>;

export interface IBrand {
  id: string;
  name: string;
}

export interface IProduct {
  id: string;
  name: string;
  brand: IBrand;
}

export interface ICampaign {
  id: string;
  title: string;
  brief: string;
  products: IProduct[];
  profileRequirements: IProfileRequirements;
}

interface Params {
  campaignId?: string;
  influencerId?: string;
}

export const getMaketCampaign = async (params: Params): Promise<ICampaign> => {
  const db = getDatabase(app);
  const dbRef = ref(db);

  if (!params?.campaignId) {
    throw new Error("campaignId is required");
  }

  const campaign = await get(
    child(dbRef, `${CAMPAIGNS}/${params.campaignId}`),
  ).then((snapshot) => {
    if (!snapshot.exists()) {
      return null as unknown as Campaign;
    }
    return campaignSchema.parse(snapshot.val());
  });

  if (!campaign) {
    return null as unknown as ICampaign;
  }

  const productIds = keys(campaign.products);

  const products: Product[] = [];
  const brands: Brand[] = [];

  for (const productId of productIds) {
    const product = await get(child(dbRef, `${PRODUCTS}/${productId}`)).then(
      (snapshot) => {
        if (!snapshot.exists()) {
          return null as unknown as Product;
        }
        return productSchema.parse(snapshot.val());
      },
    );
    products.push(product);

    const brandId = keys(product.brand)[0];
    const brand = await get(child(dbRef, `${BRANDS}/${brandId}`)).then(
      (snapshot) => {
        if (!snapshot.exists()) {
          return null as unknown as Brand;
        }
        return brandSchema.parse(snapshot.val());
      },
    );
    brands.push(brand);
  }

  console.debug(`${LOG_PREFIX}`, products, brands);

  return {
    id: campaign.id!,
    title: campaign.title,
    brief: campaign.brief,
    products: products.map((product, index) => ({
      brand: {
        id: brands[index]?.id ?? "unkwown",
        name: brands[index]?.name,
      },
      id: product.id!,
      name: product.name,
    })),
    profileRequirements: campaign.profileRequirements,
  };
};
