import { getDatabase, ref, set } from "firebase/database";
import { app } from "../utils/firebase";
import {
  CAMPAIGNS,
  CONTENTS,
  type Content,
  INFLUENCERS,
  RESOURCE,
  type Resource,
  contentSchema,
  resourceSchema,
} from "@influ-app/modeldb";
import { v4 as randomUUID } from "uuid";

const LOG_PREFIX = "postInfluencerNewContent:";

export interface IContent {}

interface Params {
  influencerId: string;
  url: string;
  title: string;
  type: string;
  userId: string;
  campaignId: string;
}

export const postInfluencerNewContent = async (
  params: Params,
): Promise<IContent> => {
  console.debug(LOG_PREFIX, "postInfluencerNewContent", params);
  const db = getDatabase(app);

  const contentId = randomUUID();

  const newResourse = resourceSchema.parse({
    url: params?.url,
    creationDate: new Date(),
    author: params?.userId,
    content: {
      [contentId]: CONTENTS,
    },
    active: true,
    type: params?.type,
  } as Resource);

  const newContent = contentSchema.parse({
    id: contentId,
    influencer: {
      [params?.influencerId]: INFLUENCERS,
    },
    title: params?.title,
    type: params?.type,
    resources: {
      [newResourse?.id]: RESOURCE,
    },
    campaign: {
      [params?.campaignId]: CAMPAIGNS,
    },
  } as Content);

  await set(ref(db, `${RESOURCE}/${newResourse?.id}`), newResourse);
  await set(ref(db, `${CONTENTS}/${newContent?.id}`), newContent);

  console.debug(LOG_PREFIX, "postInfluencerNewContent", "created!");
  return {};
};
