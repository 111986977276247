"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resourceSchema = void 0;
const zod_1 = require("zod");
const CollectionNames_1 = require("./CollectionNames");
const uuid_1 = require("uuid");
const utils_1 = require("./utils");
exports.resourceSchema = zod_1.z.object({
    id: zod_1.z.string().default(uuid_1.v4),
    url: zod_1.z.string(),
    type: zod_1.z.string(),
    author: zod_1.z.string(),
    creationDate: zod_1.z.date(),
    active: zod_1.z.boolean(),
    content: zod_1.z.record(zod_1.z.enum([CollectionNames_1.CONTENTS]))
        .refine(utils_1.onlyOneItem, "Only one content is allowed")
});
