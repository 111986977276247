import { Outlet } from "react-router-dom";
import TopBar from "../components/top-bar/Topbar";
import Styles from "./root.module.scss";

type Props = Record<string, never>;

export const Root: React.FC<Props> = () => {
  return (
    <div className={Styles.container}>
      <div className={Styles.top_section}>
        <TopBar />
      </div>
      <div className={Styles.page_content}>
        <Outlet />
      </div>
    </div>
  );
};
